import React from 'react'
import './StyleSheets/SectionCards.css'
import Categorie_Cards from "./Categorie_Cards";
import DataCategories from '../data/categories.json'


function Categories(){

    return(
        <div>
            <section className="cards">
                <div className="container_categ">
                    {
                        DataCategories.map(post => {
                            return(
                                <Categorie_Cards
                                    key={post.id}
                                    name={post.title}
                                    link={post.link}
                                />
                            )
                        })
                    }
                </div>
            </section>
        </div>
    )



}

export default Categories;