import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './global.css';
import './components/StyleSheets/SectionCards.css'
//*************************
import Header from "./components/Header/Header";
import Categories from "./components/Categories";
import Accueil from "./components/Accueil";
//*************************
import ScrollToTop from "./components/ScrollTop";
import Pages from './data/categories.json'
import Card from "./components/Card";
import ButtonCategories from "./components/ButtonCategories";
//*************************

function App() {
  return (
      <>
          <Router>
              <Header />
              <ScrollToTop>
              <Switch>
                  <Route path={"/"} exact component={Accueil} />
                  <Route path={"/Categories"} exact component={Categories} />
                  {Pages.map(post => {
                      return(
                        <Route path={"/" + post.name} exact component={()=>{
                            return createPage(post)
                        }}/>
                      )
                  })}
              </Switch>
              </ScrollToTop>
          </Router>
      </>
  );
}
function createPage(post){
    const Datas = require(`./data/${post.name.toLowerCase()}.json`)
    return(
        <div>
            <ButtonCategories />
            <section className="cards">
                <h2>{post.name}</h2>
                <div className="container">

                    {Datas.map(cardPost =>{
                        return(
                            <Card
                                key={cardPost.id}
                                img={cardPost.img}
                                source={cardPost.source}
                                name={cardPost.name}
                                tag1={cardPost.tag1}
                                tag2={cardPost.tag2}
                            />
                        )
                    })
                    }
                </div>
            </section>
        </div>
    )
}


export default App;
