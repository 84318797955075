import React from 'react'
import './StyleSheets/Card.css'


function Card(props){

    return(
        <div className="card">
            <div className="img_box">
                <a href={props.source} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "./images/" +props.img} alt={props.source} /></a>
            </div>
            <div className="tags">
                <p>{props.name}</p>
                <ul className="list">
                    <li>{props.tag1}</li>
                    <li>{props.tag2}</li>
                </ul>
            </div>
        </div>
    )



}

export default Card;