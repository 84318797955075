import React from 'react'
import './StyleSheets/Categorie_Cards.css'
import {Link} from "react-router-dom";

function Categorie_Cards(props){
    return(
        <>
            <Link to={props.link} className="categorie_cards">
                    <p>
                        {props.name}
                    </p>
            </Link>
        </>
    )



}

export default Categorie_Cards;