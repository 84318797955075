import React from 'react'
import {Link} from "react-router-dom";

function ButtonCategories(){
    return(
        <>
        <Link to={"/Categories"}>
            <div className="buttonCategories">
                <p>Catégories</p>
            </div>
        </Link>
        </>
    )



}



export default ButtonCategories;