import React from 'react'
import './Header.css'
import {Link} from 'react-router-dom'

function Header() {
    return(
        <>
            <header className="header">
                <Link to={"/"} className={"title"}>
                    🦔 Creative <span>Ressources</span>
                </Link>
                <a href="https://discords.com/bio/p/hesediel" className="button" rel="noreferrer" target="_blank">
                    Faire une suggestion
                </a>
            </header>
        </>
    )
}

export default Header;