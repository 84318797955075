import React from 'react'
import './StyleSheets/Accueil.css'
import {Link} from 'react-router-dom'
/***************************
 ***************************/
import Background from '../data/background.json'
import Challenges from '../data/challenges.json'
import Cms from '../data/cms.json'
import Colors from '../data/colors.json'
import Editeur from '../data/editeur.json'
import Fonts from '../data/fonts.json'
import Framework from '../data/framework.json'
import Hebergement from '../data/hebergements.json'
import Icones from '../data/icones.json'
import Illustrations from '../data/illustrations.json'
import Images from '../data/images.json'
import Outils from '../data/outils.json'
import ReactData from '../data/react.json'
import Shape from '../data/shape.json'
import WebDesign from '../data/webdesign.json'
import Categories from '../data/categories.json'
/***************************
 ***************************/




function Accueil(){
    let statsRessources = Background.length + Challenges.length + Cms.length + Colors.length + Editeur.length + Fonts.length + Shape.length + WebDesign.length +
        Framework.length + Hebergement.length + Icones.length + Illustrations.length + Images.length + Outils.length + ReactData.length;
    let statsCategories = Categories.length;
    return(
        <div>
            <section className="banner">
                <div className="text_area">
                    <h1>Bienvenue sur Creative <span>Ressources</span></h1>
                    <p>Retrouve plus de <span>{statsRessources}</span> ressources et <span>{statsCategories}</span> catégories pour t'aider dans la réalisation de ton site web.</p>
                    <Link to={"/categories"} className="btn">
                        Découvrir les categories
                    </Link>
                </div>
                <div className="imgbox">
                    <img src={process.env.PUBLIC_URL + 'images/accueil.svg'} alt=""/>
                </div>
            </section>
        </div>
    )



}

export default Accueil;